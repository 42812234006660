.product-image {
  height: 55vh;
  width: 65vw;
  object-fit: contain;
}

.product-title {
  font-family: Jost;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.1em;
  text-decoration: none;
  margin-top: 3rem;
}

.product-description {
  font-family: Jost;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-decoration: none;
  margin-top: 5rem;
}

.product-headings {
  flex: 1;
  font-family: Jost;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.1em;
  text-decoration: none;
}

.attributeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 4rem;
}

.attributeContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1rem;
}

.card {
  minHeight: 100px;
  padding: 0.5rem;
  width: 25vw;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-items: center;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attributeTitle {
  font-family: Jost;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.1em;
  text-decoration: none;
}

.attributeText {
  font-family: Jost;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.1em;
  text-decoration: none;
}

.spaced-left {
  align-self: flex-start;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

.product-feature {
  align-self: flex-start;
  font-family: Jost;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.1em;
  text-decoration: none;
}

.attributes {
  flex: 1
}

.pdfButton {
  height: 50px;
  width: 200px;
  background-color: #cd5630;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5rem;
}

.product-button-text {
  color: white;
  font-size: 20px;
  text-align: center;
  margin: 10px;
}

.product-ups-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  border-color: #cd5630;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.product-ups-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.product-ups-text16 {
  color: rgb(205, 86, 48);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.product-ups-container5 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  border-color: #cd5630;
  flex-direction: column;
}
.product-ups-text17 {
  color: rgb(205, 86, 48);
  font-size: 20px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.product-ups-text20 {
  color: rgb(205, 86, 48);
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: #cd5630;
}
.product-ups-text25 {
  color: rgb(205, 86, 48);
  margin-top: var(--dl-space-space-twounits);
  border-color: #cd5630;
}
.product-ups-text30 {
  color: rgb(205, 86, 48);
  margin-top: 12px;
  border-color: #cd5630;
}
.product-ups-links-container {
  width: 613px;
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-self: center;
  align-items: center;
  border-color: #cd5630;
  flex-direction: row;
  justify-content: center;
}
.product-ups-container6 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  border-color: #cd5630;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.product-ups-text33 {
  color: rgb(205, 86, 48);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.product-ups-text34 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text35 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text36 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text39 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text40 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text41 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
}
.product-ups-container7 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  border-color: #cd5630;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.product-ups-text42 {
  color: rgb(205, 86, 48);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.product-ups-text43 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .product-ups-container2 {
    display: none;
  }
  .product-ups-left {
    display: none;
  }
  .product-ups-right {
    display: none;
  }
  .product-ups-icons {
    display: none;
  }
  .product-ups-burger-menu {
    display: flex;
  }
  .product-ups-icon {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .product-ups-logo-center {
    margin-left: 0px;
  }
  .product-ups-hero1 {
    flex-direction: column;
  }
  .product-ups-footer1 {
    flex-direction: column-reverse;
  }
  .product-ups-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .product-ups-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-ups-main {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .product-ups-hero {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .product-ups-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .product-ups-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .product-ups-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .product-ups-container {
    padding-top: 55px;
  }
  .product-ups-header {
    padding: var(--dl-space-space-unit);
  }
  .product-ups-mobile-menu {
    padding: 16px;
  }
  .product-ups-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .product-ups-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .product-ups-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .product-ups-container6 {
    margin-right: var(--dl-space-space-unit);
  }
  .product-ups-container7 {
    margin-right: var(--dl-space-space-unit);
  }
}
