.item-tile-blog-post-card {
  width: 65vw;
  height: 700px;
  display: flex;
  position: relative;
  align-self: center;
  transition: 0.3s;
  flex-direction: column;
  padding-bottom: 282px;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
}
.item-tile-blog-post-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 20px rgba(205, 86, 48, 0.4); 
}
.item-tile-container {
  width: 60vw;
  height: 500px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.item-tile-image {
  width: 55vw;
  height: 282px;
  object-fit: contain;
  align-self: center;
}
.item-tile-container1 {
  width: 60vw;
  height: 220px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.item-tile-text {
  color: var(--dl-color-gray-black80);
  font-size: 22px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  line-height: 28px;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.item-tile-text1 {
  color: var(--dl-color-gray-black50);
  font-size: 16px;
  font-style: normal;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-twounits);
  text-overflow: ellipsis;
}
.item-tile-link {
  color: var(--dl-color-pallet-primary);
  height: 40px;
  width: 200px;
  background-color: var(--dl-color-pallet-primary);
  border-radius: 1rem;
  letter-spacing: 0.02;
  text-transform: none;
  text-decoration: none;
  margin-left: 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.item-tile-link:hover {
  transform: scale(1.02);
}
.button-text {
  font-size: 16px;
  text-align: center;
  font-family: Jost;
  font-weight: 400;
  color: white;
}



@media(max-width: 991px) {
  .item-tile-blog-post-card {
    max-width: 48%;
  }
}
@media(max-width: 767px) {
  .item-tile-blog-post-card {
    max-width: 100%;
  }
}
