.category-card-category-card {
  flex: 0 0 auto;
  cursor: pointer;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
}
.category-card-category-card:hover {
  color: var(--dl-color-pallet-yellow);
  transform: scale(1.2);
}
.category-card-image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-unit);
  object-position: bottom;
}
.category-card-text {
  line-height: 14px;
}
.category-card-root-class-name {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: auto;
  height: auto;
  position: 0px;
}
.category-card-root-class-name1 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: auto;
  height: auto;
  position: 0px;
}
@media(max-width: 767px) {
  .category-card-category-card {
    width: 48%;
  }
  .category-card-image {
    width: 100%;
    height: 250px;
  }
}
@media(max-width: 479px) {
  .category-card-category-card {
    width: 100%;
  }
}
