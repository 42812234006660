.about-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  padding-top: 70px;
  flex-direction: column;
}
.about-navbar {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.about-header {
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #ffffff;
}
.about-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.about-navbar1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}
.about-container02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e1a1a;
}
.about-middle {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  justify-content: center;
}
.about-left {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.about-text {
  color: #cd5630;
}
.about-text01 {
  color: #cd5630;
}
.about-image {
  flex: 1;
  width: 200px;
  height: var(--dl-size-size-medium);
  object-fit: contain;
}
.about-right {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.about-text02 {
  color: rgb(205, 86, 48);
}
.about-text03 {
  color: rgb(205, 86, 48);
}
.about-icons {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
.about-burger-menu {
  display: none;
}
.about-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.about-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.about-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.about-logo-center {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Jost;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.about-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.about-middle1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.about-text04 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text05 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text06 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text07 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.about-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.about-banner {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-950);
}
.about-container04 {
  flex: 0 0 auto;
  width: 338px;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-950);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.about-text10 {
  color: #cd5630;
  margin-bottom: var(--dl-space-space-halfunit);
}
.about-text11 {
  color: rgb(205, 86, 48);
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 1em;
  text-transform: uppercase;
}
.about-container05 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.about-image1 {
  width: 200px;
  height: 200px;
  margin-top: var(--dl-space-space-threeunits);
  object-fit: cover;
  border-radius: 100px;
}
.about-text12 {
  color: rgb(205, 86, 48);
  width: 100%;
  height: auto;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: Jost;
  font-weight: 500;
  line-height: 175%;
  font-stretch: normal;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.about-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #cd5630;
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.about-max-width {
  align-items: center;
  border-color: #cd5630;
  justify-content: center;
}
.about-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  border-color: #cd5630;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.about-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.about-text13 {
  color: rgb(205, 86, 48);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.about-container07 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  border-color: #cd5630;
  flex-direction: column;
}
.about-text14 {
  color: rgb(205, 86, 48);
  font-size: 20px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.about-text17 {
  color: rgb(205, 86, 48);
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: #cd5630;
}
.about-text22 {
  color: rgb(205, 86, 48);
  margin-top: var(--dl-space-space-twounits);
  border-color: #cd5630;
}
.about-text27 {
  color: rgb(205, 86, 48);
  margin-top: 12px;
  border-color: #cd5630;
}
.about-links-container {
  width: 613px;
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-self: center;
  align-items: center;
  border-color: #cd5630;
  flex-direction: row;
  justify-content: center;
}
.about-container08 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  border-color: #cd5630;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.about-text30 {
  color: rgb(205, 86, 48);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.about-text31 {
  color: #cd5630;
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.about-text32 {
  color: #cd5630;
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.about-text33 {
  color: #cd5630;
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.about-text34 {
  color: #cd5630;
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.about-text35 {
  color: #cd5630;
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.about-text36 {
  color: #cd5630;
  border-color: #cd5630;
}
.about-container09 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  border-color: #cd5630;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.about-text37 {
  color: rgb(205, 86, 48);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.about-text38 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .about-container02 {
    display: none;
  }
  .about-left {
    display: none;
  }
  .about-right {
    display: none;
  }
  .about-icons {
    display: none;
  }
  .about-burger-menu {
    display: flex;
  }
  .about-icon {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .about-logo-center {
    margin-left: 0px;
  }
  .about-footer1 {
    flex-direction: column-reverse;
  }
  .about-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .about-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-main {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .about-text12 {
    max-width: 770px;
  }
  .about-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .about-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .about-container {
    padding-top: 55px;
  }
  .about-header {
    padding: var(--dl-space-space-unit);
  }
  .about-mobile-menu {
    padding: 16px;
  }
  .about-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .about-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .about-container08 {
    margin-right: var(--dl-space-space-unit);
  }
  .about-container09 {
    margin-right: var(--dl-space-space-unit);
  }
}
