.home-container {
    width: 100%;
    display: flex;
    min-height: 100vh;
    overflow-x: hidden;
    align-items: center;
    padding-top: 70px;
    flex-direction: column;
  }
  .home-navbar {
    top: 0;
    width: 100%;
    display: flex;
    z-index: 1000;
    position: fixed;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
  }
  .home-header {
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: space-between;
    background-color: #ffffff;
  }
  .home-container01 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: space-between;
    flex-direction: column;
  }
  .home-navbar1 {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
  }
  .home-container02 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1e1a1a;
  }
  .home-middle {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 1;
    border-color: transparent;
    justify-content: center;
  }
  .home-left {
    display: flex;
    grid-gap: 45px;
    position: relative;
    align-items: center;
  }
  .home-text {
    color: #cd5630;
  }
  .home-thq-dropdown {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
  }
  .home-dropdown-toggle {
    fill: var(--dl-color-pallet-yellow);
    color: var(--dl-color-pallet-yellow);
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius2);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text01 {
    color: #cd5630;
  }
  .home-dropdown-list {
    left: 0%;
    width: max-content;
    display: none;
    z-index: 100;
    position: absolute;
    min-width: 100%;
    transition: 0.3s;
    align-items: stretch;
    border-color: transparent;
    border-width: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    list-style-type: none;
    background-color: #cd5630;
    list-style-position: inside;
  }
  .home-dropdown {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
  }
  .home-dropdown-toggle1 {
    fill: var(--dl-color-pallet-yellow);
    color: var(--dl-color-pallet-yellow);
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-dropdown-toggle1:hover {
    fill: #fff;
    color: #fff;
    background-color: var(--dl-color-pallet-yellow);
  }
  .home-text02 {
    color: #ffffff;
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-dropdown1 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
  }
  .home-dropdown-toggle2 {
    fill: var(--dl-color-pallet-yellow);
    color: var(--dl-color-pallet-yellow);
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-dropdown-toggle2:hover {
    fill: #fff;
    color: #fff;
    background-color: var(--dl-color-pallet-yellow);
  }
  .home-text03 {
    color: #ffffff;
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-dropdown2 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
  }
  .home-dropdown-toggle3 {
    fill: var(--dl-color-pallet-yellow);
    color: var(--dl-color-pallet-yellow);
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-dropdown-toggle3:hover {
    fill: #fff;
    color: #fff;
    background-color: var(--dl-color-pallet-yellow);
  }
  .home-text04 {
    color: #ffffff;
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-dropdown3 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
  }
  .home-dropdown-toggle4 {
    fill: var(--dl-color-pallet-yellow);
    color: var(--dl-color-pallet-yellow);
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-dropdown-toggle4:hover {
    fill: #fff;
    color: #fff;
    background-color: var(--dl-color-pallet-yellow);
  }
  .home-text05 {
    color: #ffffff;
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-dropdown4 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
  }
  .home-dropdown-toggle5 {
    fill: var(--dl-color-pallet-yellow);
    color: var(--dl-color-pallet-yellow);
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-dropdown-toggle5:hover {
    fill: #fff;
    color: #fff;
    background-color: var(--dl-color-pallet-yellow);
  }
  .home-text06 {
    color: #ffffff;
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-dropdown5 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
  }
  .home-dropdown-toggle6 {
    fill: var(--dl-color-pallet-yellow);
    color: var(--dl-color-pallet-yellow);
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-dropdown-toggle6:hover {
    fill: #fff;
    color: #fff;
    background-color: var(--dl-color-pallet-yellow);
  }
  .home-text07 {
    color: #ffffff;
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-image {
    flex: 1;
    width: 200px;
    height: var(--dl-size-size-medium);
    object-fit: contain;
  }
  .home-right {
    display: flex;
    grid-gap: 45px;
    position: relative;
    align-items: flex-start;
  }
  .home-text08 {
    color: rgb(205, 86, 48);
  }
  .home-text09 {
    color: rgb(205, 86, 48);
  }
  .home-icons {
    border: 2px dashed rgba(120, 120, 120, 0.4);
    display: flex;
  }
  .home-burger-menu {
    display: none;
  }
  .home-icon {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    display: none;
  }
  .home-mobile-menu {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    padding: 32px;
    z-index: 100;
    position: absolute;
    transform: translateX(100%);
    transition: 0.3s;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }
  .home-nav {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-container03 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
  }
  .home-logo-center {
    color: var(--dl-color-gray-black);
    height: auto;
    font-size: 24px;
    font-family: Jost;
    font-weight: 500;
    line-height: 20px;
    margin-left: var(--dl-space-space-fourunits);
    margin-right: var(--dl-space-space-fourunits);
    letter-spacing: 0.4em;
    text-decoration: none;
  }
  .home-close-mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-icon02 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .home-middle1 {
    gap: var(--dl-space-space-unit);
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
  }
  .home-text10 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 13px;
    text-align: left;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .home-text11 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 13px;
    text-align: left;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .home-text12 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 13px;
    text-align: left;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .home-text13 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 13px;
    text-align: left;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .home-text14 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 13px;
    text-align: left;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .home-text15 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    font-size: 13px;
    text-align: left;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
  }
  .home-icon04 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
  }
  .home-icon06 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
  }
  .home-icon08 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
  }
  .home-main {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
    background-color: #ffffff;
  }
  .home-max-width {
    max-width: 1032px;
  }
  .home-hero1 {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-container04 {
    display: flex;
    margin-right: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-info {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 1;
    border-color: transparent;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image1 {
    width: 2px;
    height: 52px;
    margin: 0 14px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .home-text16 {
    color: #cd5630;
    font-size: 12px;
    text-align: left;
    font-family: Jost;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
  }
  .home-text17 {
    color: #cd5630;
    max-width: 450px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container05 {
    display: flex;
    align-items: flex-end;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: row;
  }
  .home-text25 {
    color: rgb(205, 86, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-right: 4px;
    letter-spacing: 0.1em;
  }
  .home-btn-group {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-button {
    color: rgb(205, 86, 48);
    border-color: #cd5630;
  }
  .home-image2 {
    width: 100%;
    position: relative;
    max-width: 579px;
    box-sizing: border-box;
    max-height: 579px;
    object-fit: contain;
    border-color: transparent;
  }
  .home-cards-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    grid-gap: var(--dl-space-space-twounits);
    position: relative;
    flex-wrap: wrap;
    max-width: 1080px;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: row;
    justify-content: space-between;
  }
  .home-banner {
    flex: 0 0 auto;
    width: 100%;
    height: 1px;
    display: flex;
    margin-top: var(--dl-space-space-threeunits);
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-950);
  }
  .home-container06 {
    flex: 0 0 auto;
    width: 281px;
    height: 100px;
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-950);
    border-style: solid;
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .home-text26 {
    color: #cd5630;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text27 {
    color: rgb(205, 86, 48);
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 1em;
    text-transform: uppercase;
  }
  .home-container07 {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-container08 {
    width: 100%;
    display: flex;
    position: relative;
    max-width: 770px;
    box-sizing: border-box;
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    flex-shrink: 1;
    border-color: transparent;
    flex-direction: column;
  }
  .home-text28 {
    color: rgb(205, 86, 48);
    width: 100%;
    height: auto;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-family: Jost;
    font-weight: 500;
    line-height: 175%;
    font-stretch: normal;
    margin-bottom: var(--dl-space-space-threeunits);
    text-decoration: none;
  }
  .home-button1 {
    color: rgb(205, 86, 48);
    border-color: #cd5630;
  }
  .home-trending-items {
    align-items: flex-start;
    justify-content: center;
  }
  .home-gallery {
    width: 100%;
    display: flex;
    align-items: stretch;
  }
  .home-left1 {
    flex: 1;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image3 {
    flex: 1;
    width: 300px;
    height: 825px;
    object-fit: cover;
  }
  .home-right1 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .home-top {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .home-left2 {
    flex: 1;
    height: 485px;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image4 {
    width: auto;
    height: 100%;
    object-fit: cover;
  }
  .home-bottom {
    flex: 1;
    width: 100%;
    display: flex;
  }
  .home-left3 {
    flex: 1;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image5 {
    flex: 1;
    width: 577px;
    height: 291px;
    object-fit: cover;
  }
  .home-iframe {
    width: 1057px;
    height: 803px;
  }
  .home-footer {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    border-color: #cd5630;
    border-width: 1px;
    flex-direction: column;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-max-width3 {
    align-items: center;
    border-color: #cd5630;
    justify-content: center;
  }
  .home-footer1 {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: flex-start;
    padding-top: var(--dl-space-space-sixunits);
    border-color: #cd5630;
    flex-direction: row;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: space-between;
  }
  .home-container09 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: space-between;
    flex-direction: column;
  }
  .home-text29 {
    color: rgb(205, 86, 48);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 44px;
  }
  .home-container10 {
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: flex-start;
    border-color: #cd5630;
    flex-direction: column;
  }
  .home-text30 {
    color: rgb(205, 86, 48);
    font-size: 20px;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text33 {
    color: rgb(205, 86, 48);
    margin-top: var(--dl-space-space-oneandhalfunits);
    border-color: #cd5630;
  }
  .home-text38 {
    color: rgb(205, 86, 48);
    margin-top: var(--dl-space-space-twounits);
    border-color: #cd5630;
  }
  .home-text43 {
    color: rgb(205, 86, 48);
    margin-top: 12px;
    border-color: #cd5630;
  }
  .home-links-container {
    width: 613px;
    display: flex;
    grid-gap: var(--dl-space-space-threeunits);
    align-self: center;
    align-items: center;
    border-color: #cd5630;
    flex-direction: row;
    justify-content: center;
  }
  .home-container11 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    border-color: #cd5630;
    margin-right: var(--dl-space-space-sixunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-text46 {
    color: rgb(205, 86, 48);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 44px;
  }
  .home-text47 {
    color: rgb(205, 86, 48);
    border-color: #cd5630;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text48 {
    color: rgb(205, 86, 48);
    border-color: #cd5630;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text49 {
    color: rgb(205, 86, 48);
    border-color: #cd5630;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text52 {
    color: rgb(205, 86, 48);
    border-color: #cd5630;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text53 {
    color: rgb(205, 86, 48);
    border-color: #cd5630;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text54 {
    color: rgb(205, 86, 48);
    border-color: #cd5630;
  }
  .home-container12 {
    flex: 0 0 auto;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    border-color: #cd5630;
    margin-right: var(--dl-space-space-sixunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-text55 {
    color: rgb(205, 86, 48);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 44px;
  }
  .home-text56 {
    color: rgb(205, 86, 48);
    border-color: #cd5630;
    margin-bottom: var(--dl-space-space-unit);
  }
  @media(max-width: 991px) {
    .home-container02 {
      display: none;
    }
    .home-left {
      display: none;
    }
    .home-right {
      display: none;
    }
    .home-icons {
      display: none;
    }
    .home-burger-menu {
      display: flex;
    }
    .home-icon {
      width: 24px;
      height: 24px;
      display: flex;
    }
    .home-logo-center {
      margin-left: 0px;
    }
    .home-hero1 {
      flex-direction: column;
    }
    .home-container04 {
      align-items: center;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-twounits);
      padding-right: 0px;
    }
    .home-text17 {
      text-align: center;
    }
    .home-left1 {
      width: 100%;
    }
    .home-footer1 {
      flex-direction: column-reverse;
    }
    .home-links-container {
      margin-bottom: var(--dl-space-space-threeunits);
    }
  }
  @media(max-width: 767px) {
    .home-burger-menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .home-main {
      padding-bottom: var(--dl-space-space-threeunits);
    }
    .home-hero {
      padding-top: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-unit);
    }
    .home-hero1 {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-cards-container {
      grid-gap: var(--dl-space-space-unit);
    }
    .home-text28 {
      max-width: 770px;
    }
    .home-gallery {
      flex-direction: column;
    }
    .home-top {
      flex-direction: column;
    }
    .home-bottom {
      flex-direction: column;
    }
    .home-footer1 {
      padding-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
      padding-bottom: var(--dl-space-space-threeunits);
    }
    .home-links-container {
      margin-top: var(--dl-space-space-twounits);
      flex-direction: column;
      justify-content: space-between;
    }
  }
  @media(max-width: 479px) {
    .home-container {
      padding-top: 55px;
    }
    .home-header {
      padding: var(--dl-space-space-unit);
    }
    .home-mobile-menu {
      padding: 16px;
    }
    .home-hero1 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-container04 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-btn-group {
      flex-direction: column;
    }
    .home-footer1 {
      padding: var(--dl-space-space-unit);
    }
    .home-links-container {
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
    }
    .home-container11 {
      margin-right: var(--dl-space-space-unit);
    }
    .home-container12 {
      margin-right: var(--dl-space-space-unit);
    }
  }
  