.product-ups-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  padding-top: 70px;
  flex-direction: column;
}
.product-ups-navbar {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.product-ups-header {
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #ffffff;
}
.product-ups-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.product-ups-navbar1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}
.product-ups-container2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e1a1a;
}
.product-ups-middle {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  justify-content: center;
}
.product-ups-left {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: center;
}
.product-ups-text {
  color: #cd5630;
}
.product-ups-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.product-ups-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.product-ups-text01 {
  color: #cd5630;
}
.product-ups-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: transparent;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  list-style-type: none;
  background-color: #cd5630;
  list-style-position: inside;
}
.product-ups-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.product-ups-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.product-ups-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.product-ups-text02 {
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.product-ups-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.product-ups-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.product-ups-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.product-ups-text03 {
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.product-ups-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.product-ups-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.product-ups-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.product-ups-text04 {
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.product-ups-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.product-ups-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.product-ups-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.product-ups-text05 {
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.product-ups-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.product-ups-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.product-ups-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.product-ups-text06 {
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.product-ups-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.product-ups-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.product-ups-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.product-ups-text07 {
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.product-ups-image {
  flex: 1;
  width: 200px;
  height: var(--dl-size-size-medium);
  object-fit: contain;
}
.product-ups-right {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.product-ups-text08 {
  color: rgb(205, 86, 48);
}
.product-ups-text09 {
  color: rgb(205, 86, 48);
}
.product-ups-icons {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
.product-ups-burger-menu {
  display: none;
}
.product-ups-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.product-ups-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.product-ups-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-ups-container3 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.product-ups-logo-center {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Jost;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.product-ups-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-ups-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.product-ups-middle1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.product-ups-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.product-ups-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.product-ups-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.product-ups-text13 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.product-ups-text14 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.product-ups-text15 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.product-ups-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.product-ups-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.product-ups-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.product-ups-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.product-ups-hero {
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: #ffffff;
}
.product-ups-max-width {
  max-width: 1032px;
}
.product-ups-hero1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.product-ups-ul {
  flex-direction: row;
}
.product-ups-li {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.product-ups-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #cd5630;
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.product-ups-max-width1 {
  align-items: center;
  border-color: #cd5630;
  justify-content: center;
}
.product-ups-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  border-color: #cd5630;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.product-ups-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.product-ups-text16 {
  color: rgb(205, 86, 48);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.product-ups-container5 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  border-color: #cd5630;
  flex-direction: column;
}
.product-ups-text17 {
  color: rgb(205, 86, 48);
  font-size: 20px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.product-ups-text20 {
  color: rgb(205, 86, 48);
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: #cd5630;
}
.product-ups-text25 {
  color: rgb(205, 86, 48);
  margin-top: var(--dl-space-space-twounits);
  border-color: #cd5630;
}
.product-ups-text30 {
  color: rgb(205, 86, 48);
  margin-top: 12px;
  border-color: #cd5630;
}
.product-ups-links-container {
  width: 613px;
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-self: center;
  align-items: center;
  border-color: #cd5630;
  flex-direction: row;
  justify-content: center;
}
.product-ups-container6 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  border-color: #cd5630;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.product-ups-text33 {
  color: rgb(205, 86, 48);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.product-ups-text34 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text35 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text36 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text39 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text40 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
.product-ups-text41 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
}
.product-ups-container7 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  border-color: #cd5630;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.product-ups-text42 {
  color: rgb(205, 86, 48);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.product-ups-text43 {
  color: rgb(205, 86, 48);
  border-color: #cd5630;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .product-ups-container2 {
    display: none;
  }
  .product-ups-left {
    display: none;
  }
  .product-ups-right {
    display: none;
  }
  .product-ups-icons {
    display: none;
  }
  .product-ups-burger-menu {
    display: flex;
  }
  .product-ups-icon {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .product-ups-logo-center {
    margin-left: 0px;
  }
  .product-ups-hero1 {
    flex-direction: column;
  }
  .product-ups-footer1 {
    flex-direction: column-reverse;
  }
  .product-ups-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .product-ups-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-ups-main {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .product-ups-hero {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .product-ups-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .product-ups-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .product-ups-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .product-ups-container {
    padding-top: 55px;
  }
  .product-ups-header {
    padding: var(--dl-space-space-unit);
  }
  .product-ups-mobile-menu {
    padding: 16px;
  }
  .product-ups-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .product-ups-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .product-ups-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .product-ups-container6 {
    margin-right: var(--dl-space-space-unit);
  }
  .product-ups-container7 {
    margin-right: var(--dl-space-space-unit);
  }
}
