.section-heading-section-heading {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.section-heading-text {
  color: #cd5630;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.section-heading-text1 {
  color: #cd5630;
  text-align: center;
  line-height: 24px;
}

